import {
    Box,
    Collapse,
    IconButton,
    LabelDisplayedRowsArgs,
    Table as MaterialTable,
    TablePagination as MaterialTablePagination,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { StandardLonghandProperties } from 'csstype';
import { observer } from 'mobx-react';
import React from 'react';
import {
    Column,
    HeaderProps,
    SortingRule,
    useExpanded,
    usePagination,
    useRowSelect,
    useSortBy,
    useTable
} from 'react-table';
import styled from 'styled-components';
import { WrapRequest } from 'wrap-request';
import { I18n } from '../../core/i18n';
import { injectTSDI } from '../../core/tsdi';
import { ContextMenu, ContextMenuOption } from '../context-menu';
import { Spinner } from '../spinner';
import { SearchFilter } from './search-filter';
import { SelectHeaderCheckbox, SelectRowCheckbox } from './select-checkbox';

const StyledTable = styled(MaterialTable)`
    .MuiTableCell-stickyHeader {
        top: 60px;
        background-color: #fff;
    }
    .cell-level-2 {
        background-color: ${({ theme }) => theme.palette.grey[100]};
        border-bottom: none;
        position: relative;
        top: -1px;
        &:first-child {
            padding-left: 28px !important;
        }
    }
    .row-level-2 + .row-level-1 {
        border-top: 1px solid rgba(224, 224, 224, 1);
    }
`;
export type ReactColumn<T extends object = {}> = Column<T> & {
    style?: React.CSSProperties;
};

interface ObjectWithId {
    id: number;
    [key: string]: any;
}

export interface ResultPageDto<T = any> {
    content: T[];
    number?: number;
    size?: number;
    totalElements?: number;
}

// tslint:disable-next-line:no-any
interface TableProps<T extends ObjectWithId[] = ObjectWithId[]> {
    className?: string;
    columns: ReactColumn<T[0]>[];
    items: WrapRequest<ResultPageDto<T[0]>>;
    contextMenu?: ContextMenuOption<T[0]>[];
    emptyText?: string;
    stickyHeader?: boolean;
    canEdit?: boolean;
    initialSearch?: string;
    searchPlaceholder?: string;
    sorting?: SortingRule<object>;
    tableLayout?: StandardLonghandProperties['tableLayout'];
    cellPadding?: number;
    renderRowSubComponent?: ({ row }: { row: T[0] }) => JSX.Element;
    onSortingChange?(sorting?: SortingRule<object>): void;
    onSearchChange?(value?: string): void;
    onChangePage?(value: number): void;
    onPageSizeChange?(pageSize: number): void;
    onSelectionChange?(selection: Record<string, boolean>): void;
}

function TableContent(props: { colSpan: number; children: React.ReactNode }) {
    return (
        <TableRow>
            <TableCell colSpan={props.colSpan}>{props.children}</TableCell>
        </TableRow>
    );
}

const TableContainer = styled.div`
    overflow: auto;
`;

const TopBarContainer = styled.div`
    position: relative;
    height: 52px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
`;

const TablePagination = styled(MaterialTablePagination)`
    border: 'none';
    .MuiTablePagination-select {
        padding-top: 7px;
    }
`;

// tslint:disable-next-line:no-any
export const TableComponent = observer(
    <T extends any[]>({
        className,
        columns,
        items,
        contextMenu,
        stickyHeader,
        emptyText = 'No entries',
        canEdit = true,
        searchPlaceholder,
        onChangePage,
        onPageSizeChange,
        onSearchChange,
        onSortingChange,
        onSelectionChange,
        sorting,
        initialSearch,
        cellPadding = 16,
        tableLayout = 'fixed',
        renderRowSubComponent
    }: TableProps<T>) => {
        const { __ } = injectTSDI(I18n);
        const {
            $: { content, size = 0, totalElements = 0, number: currentPage = 0 }
        } = items;

        const {
            getTableProps,
            headerGroups,
            rows,
            prepareRow,
            state: { pageSize, pageIndex, selectedRowIds }
        } = useTable(
            {
                columns: columns as Column<object>[],
                data: content || [],
                useControlledState: (state) => ({
                    ...state,
                    pageSize: size,
                    pageIndex: currentPage
                }),
                getRowId: (row) => `${(row as ObjectWithId).id}`
            },
            useSortBy,
            useExpanded,
            usePagination,
            useRowSelect,

            (hooks) => {
                if (onSelectionChange) {
                    hooks.allColumns.push((columns) => [
                        // Let's make a column for selection
                        {
                            id: 'selection',
                            Header: ({
                                getToggleAllRowsSelectedProps
                            }: // tslint:disable-next-line:no-any
                            HeaderProps<any>) => (
                                <Box width={40}>
                                    <SelectHeaderCheckbox
                                        {...getToggleAllRowsSelectedProps()}
                                    />
                                </Box>
                            ),
                            // style: { width: 55 },
                            // The cell can use the individual row's getToggleRowSelectedProps method
                            // to the render a checkbox
                            Cell: ({ row }) => (
                                <Box width={40}>
                                    <SelectRowCheckbox
                                        {...row.getToggleRowSelectedProps()}
                                    />
                                </Box>
                            )
                        },
                        ...columns
                    ]);
                }
            }
        );

        const colSpan = columns.length + (contextMenu?.length ? 1 : 0);

        const handleChangeRowsPerPage = (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
            onPageSizeChange?.(Number(event.target.value));
        };

        const handleSortingChange = (id: string) => {
            if (sorting?.id === id) {
                return onSortingChange?.({ id, desc: !sorting.desc });
            }
            onSortingChange?.({ id, desc: true });
        };

        const showTopBar =
            totalElements > 15 || onSearchChange || onSelectionChange;

        React.useEffect(() => {
            onSelectionChange?.(selectedRowIds);
        }, [selectedRowIds]);

        const Pagination = () =>
            totalElements > 15 ? (
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            <TablePagination
                                rowsPerPageOptions={[15, 30, 60]}
                                colSpan={
                                    contextMenu
                                        ? columns.length + 1
                                        : columns.length
                                }
                                count={totalElements}
                                rowsPerPage={pageSize}
                                page={pageIndex}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': __(
                                            'cp.table.rows.per.page'
                                        )
                                    }
                                }}
                                onPageChange={(
                                    __: React.MouseEvent<HTMLButtonElement> | null,
                                    page: number
                                ) => void onChangePage?.(page)}
                                labelRowsPerPage={__('cp.table.rows.per.page')}
                                labelDisplayedRows={({
                                    from,
                                    to,
                                    count
                                }: LabelDisplayedRowsArgs) =>
                                    `${from}-${to} ${__(
                                        'cp.table.of'
                                    )} ${count}`
                                }
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </tr>
                    </thead>
                </table>
            ) : null;

        return (
            <TableContainer className={className}>
                {showTopBar && (
                    <TopBarContainer>
                        {onSearchChange && (
                            <SearchFilter
                                placeholder={searchPlaceholder}
                                initialValue={initialSearch}
                                onChange={onSearchChange}
                            />
                        )}

                        <Pagination />
                    </TopBarContainer>
                )}
                <Box sx={{ overflow: 'auto' }}>
                    <StyledTable
                        {...getTableProps()}
                        stickyHeader={stickyHeader}
                        sx={{
                            tableLayout
                        }}
                    >
                        <TableHead>
                            {headerGroups.map((headerGroup, i) => (
                                <React.Fragment key={i}>
                                    <TableRow
                                        {...headerGroup.getHeaderGroupProps()}
                                    >
                                        {renderRowSubComponent && (
                                            <TableCell
                                                align="right"
                                                width={50}
                                            />
                                        )}
                                        {headerGroup.headers.map((column) => {
                                            const active =
                                                column.id === sorting?.id
                                                    ? sorting
                                                    : undefined;

                                            const direction = active
                                                ? active.desc
                                                    ? 'desc'
                                                    : 'asc'
                                                : undefined;

                                            const customStyle =
                                                (column as ReactColumn)
                                                    ?.style || {};

                                            return (
                                                <TableCell
                                                    {...column.getHeaderProps()}
                                                    key={column.id}
                                                    style={{
                                                        padding: cellPadding,
                                                        ...customStyle
                                                    }}
                                                >
                                                    {column.canSort ? (
                                                        <TableSortLabel
                                                            active={Boolean(
                                                                active
                                                            )}
                                                            // react-table has a unsorted state which is not treated here
                                                            direction={
                                                                direction
                                                            }
                                                            onClick={() =>
                                                                void handleSortingChange(
                                                                    column.id
                                                                )
                                                            }
                                                        >
                                                            <Box
                                                                fontWeight={600}
                                                            >
                                                                {column.render(
                                                                    'Header'
                                                                )}
                                                            </Box>
                                                        </TableSortLabel>
                                                    ) : (
                                                        <Box fontWeight={600}>
                                                            {column.render(
                                                                'Header'
                                                            )}
                                                        </Box>
                                                    )}
                                                </TableCell>
                                            );
                                        })}
                                        {canEdit && contextMenu?.length && (
                                            <TableCell
                                                align="right"
                                                width={60}
                                            />
                                        )}
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableHead>
                        <TableBody>
                            {items.match({
                                error: (e) => (
                                    <TableContent colSpan={colSpan}>
                                        <Typography>{e.message}</Typography>
                                    </TableContent>
                                ),
                                empty: () => (
                                    <TableContent colSpan={colSpan}>
                                        <Typography>{emptyText}</Typography>
                                    </TableContent>
                                ),
                                loading: () => (
                                    <TableContent colSpan={colSpan}>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                        >
                                            <Spinner />
                                        </Box>
                                    </TableContent>
                                ),
                                fetched: (__) => {
                                    if (rows.length === 0) {
                                        return (
                                            <TableContent colSpan={colSpan}>
                                                <Typography>
                                                    {emptyText}
                                                </Typography>
                                            </TableContent>
                                        );
                                    }
                                    return rows.map((row) => {
                                        prepareRow(row);

                                        const level = (row.original as any)
                                            .level as number | undefined;

                                        return (
                                            <React.Fragment key={row.id}>
                                                <TableRow
                                                    {...row.getRowProps()}
                                                    className={
                                                        level
                                                            ? `row-level-${level}`
                                                            : ''
                                                    }
                                                    sx={{
                                                        '& > *': {
                                                            ...(renderRowSubComponent
                                                                ? {
                                                                      borderBottom:
                                                                          'unset'
                                                                  }
                                                                : {})
                                                        }
                                                    }}
                                                >
                                                    {renderRowSubComponent && (
                                                        <TableCell width={70}>
                                                            <span
                                                                {...row.getToggleRowExpandedProps()}
                                                            >
                                                                <IconButton size="small">
                                                                    {row.isExpanded ? (
                                                                        <KeyboardArrowUpIcon />
                                                                    ) : (
                                                                        <KeyboardArrowDownIcon />
                                                                    )}
                                                                </IconButton>
                                                            </span>
                                                        </TableCell>
                                                    )}
                                                    {row.cells.map((cell) => {
                                                        const { column } = cell;
                                                        const customStyle =
                                                            (
                                                                column as ReactColumn
                                                            )?.style || {};

                                                        return (
                                                            <TableCell
                                                                {...cell.getCellProps()}
                                                                key={
                                                                    cell.column
                                                                        .id
                                                                }
                                                                style={{
                                                                    whiteSpace:
                                                                        'nowrap',
                                                                    textOverflow:
                                                                        'ellipsis',
                                                                    overflow:
                                                                        'hidden',
                                                                    padding:
                                                                        cellPadding,
                                                                    ...customStyle
                                                                }}
                                                                className={
                                                                    level
                                                                        ? `cell-level-${level}`
                                                                        : ''
                                                                }
                                                            >
                                                                {cell.render(
                                                                    'Cell'
                                                                )}
                                                            </TableCell>
                                                        );
                                                    })}
                                                    {canEdit &&
                                                        contextMenu?.length && (
                                                            <TableCell
                                                                align="right"
                                                                width={60}
                                                            >
                                                                <ContextMenu
                                                                    items={contextMenu.map(
                                                                        (
                                                                            menu
                                                                        ) => ({
                                                                            ...menu,
                                                                            value: row.original
                                                                        })
                                                                    )}
                                                                />
                                                            </TableCell>
                                                        )}
                                                </TableRow>
                                                {renderRowSubComponent && (
                                                    <TableRow>
                                                        <TableCell
                                                            style={{
                                                                paddingBottom: 0,
                                                                paddingTop: 0
                                                            }}
                                                            colSpan={
                                                                row.cells
                                                                    .length +
                                                                (canEdit &&
                                                                contextMenu?.length
                                                                    ? 2
                                                                    : 1)
                                                            }
                                                            width={50}
                                                        >
                                                            <Collapse
                                                                in={
                                                                    row.isExpanded
                                                                }
                                                                timeout="auto"
                                                                unmountOnExit
                                                            >
                                                                {renderRowSubComponent?.(
                                                                    {
                                                                        row: row.original as T
                                                                    }
                                                                )}
                                                            </Collapse>
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </React.Fragment>
                                        );
                                    });
                                }
                            })}
                        </TableBody>
                    </StyledTable>
                </Box>

                <Pagination />
            </TableContainer>
        );
    }
);

export const Table = React.memo(
    TableComponent,
    (prevProps, nextProps) => prevProps.sorting === nextProps.sorting
) as typeof TableComponent;
