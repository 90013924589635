import { FieldState, FormState } from 'formstate';
import { useWrapRequest } from 'use-wrap-request';

import {
    ActivityEventType,
    WorkoutEvent,
    WorkoutType
} from '@api/FitnessActivityClient';
import { toZonedDateTime, ZonedDateTime } from '@ms-rewards/date-lib';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Api } from '../../../../../core/api';
import {
    positive,
    required,
    useModel,
    wrapInFormField
} from '../../../../../core/forms';
import { injectTSDI } from '../../../../../core/tsdi';
import { UserContext } from '../../../../../users/user';
import { EmployeeContext } from '../../../index';

export function useStore() {
    const { fitnessActivityClient } = injectTSDI(Api);
    const employee = React.useContext(EmployeeContext);
    const user = React.useContext(UserContext);

    const addWorkoutRequest = useWrapRequest(
        async (data: WorkoutEvent) =>
            fitnessActivityClient.admin.activitySupportControllerCreateWorkoutActivity(
                data
            ),
        { successMessage: () => 'Workout added' }
    );

    const model = useModel(() => {
        const now = ZonedDateTime.now('Europe/Berlin');
        const form = new FormState({
            workoutType: new FieldState<WorkoutType>(
                WorkoutType.CYCLING
            ).validators(required()),
            from: new FieldState<string>(now.toString()).validators(required()),
            to: new FieldState<string>(now.toString()).validators(required()),
            distance: new FieldState<number>(0).validators(
                required(),
                positive()
            )
        });

        return {
            form,
            fields: {
                workoutType: wrapInFormField(form.$.workoutType),
                from: wrapInFormField(form.$.from),
                to: wrapInFormField(form.$.to),
                distance: wrapInFormField(form.$.distance)
            }
        };
    });

    const onSubmitHandler = async () => {
        const validation = await model.form.validate();
        const { workoutType, from, to, distance } = model.fields;

        const userDay = toZonedDateTime(
            from.value,
            ZonedDateTime.getLocalTZ()
        ).format('yyyy-MM-DD');

        if (validation.hasError) {
            throw new Error(model.form.error as string);
        }
        try {
            await addWorkoutRequest.request([
                {
                    messageId: uuidv4().toString(),
                    userId: employee.mySportsId || user.id,
                    timestamp: from.value,
                    userDay: userDay,
                    type: ActivityEventType.WORKOUT,
                    payload: {
                        workoutType: workoutType.value,
                        sources: ['com.mysports.support.admin-portal'],
                        devices: [],
                        dateTimeRange: {
                            from: from.value.toString(),
                            to: to.value.toString()
                        },
                        properties: {
                            distance: distance.value,
                            speedEntries: []
                        }
                    }
                }
            ]);
        } catch (err) {
            alert(err);
        }
    };

    return {
        fields: model.fields,
        errors: model.form.error,
        onSubmitHandler,
        addWorkoutRequest: addWorkoutRequest
    };
}
