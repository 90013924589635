import { FieldState, FormState } from 'formstate';
import { useWrapRequest } from 'use-wrap-request';

import {
    ActivityEventType,
    FacilityVisitEvent,
    FacilityVisitType
} from '@api/FitnessActivityClient';
import { toZonedDateTime, ZonedDateTime } from '@ms-rewards/date-lib';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Api } from '../../../../../core/api';
import { required, useModel, wrapInFormField } from '../../../../../core/forms';
import { injectTSDI } from '../../../../../core/tsdi';
import { UserContext } from '../../../../../users/user';
import { EmployeeContext } from '../../../index';

export function useStore() {
    const { fitnessActivityClient } = injectTSDI(Api);
    const employee = React.useContext(EmployeeContext);
    const user = React.useContext(UserContext);

    const addFacilityVisitRequest = useWrapRequest(
        async (data: FacilityVisitEvent) => {
            return await fitnessActivityClient.admin.activitySupportControllerCreateFacilityVisitActivity(
                data
            );
        },
        { successMessage: () => 'Facility visit added' }
    );

    const model = useModel(() => {
        const now = ZonedDateTime.now('Europe/Berlin');
        const form = new FormState({
            from: new FieldState<string>(now.toString()).validators(required()),
            to: new FieldState<string>(now.toString()).validators(required()),
            facilityId: new FieldState<string>('').validators(required()),
            facilityName: new FieldState<string>('').validators(required())
        });

        return {
            form,
            fields: {
                from: wrapInFormField(form.$.from),
                to: wrapInFormField(form.$.to),
                facilityId: wrapInFormField(form.$.facilityId),
                facilityName: wrapInFormField(form.$.facilityName)
            }
        };
    });

    const onSubmitHandler = async () => {
        const validation = await model.form.validate();
        const { from, to, facilityId, facilityName } = model.fields;
        const userDay = toZonedDateTime(
            from.value,
            ZonedDateTime.getLocalTZ()
        ).format('yyyy-MM-DD');
        if (validation.hasError) {
            throw new Error(model.form.error as string);
        }
        try {
            await addFacilityVisitRequest.request([
                {
                    messageId: uuidv4().toString(),
                    userId: employee.mySportsId || user.id,
                    timestamp: from.value,
                    userDay: userDay,
                    type: ActivityEventType.STUDIO_VISIT,
                    facilityId: facilityId.value,
                    facilityName: facilityName.value,
                    facilityVisitType: FacilityVisitType.AUTOMATIC,
                    visitDateRange: {
                        from: from.value.toString(),
                        to: to.value.toString()
                    },
                    studioName: facilityName.value
                }
            ]);
        } catch (err) {
            alert(err);
        }
    };

    return {
        fields: model.fields,
        errors: model.form.error,
        onSubmitHandler,
        addFacilityVisitRequest: addFacilityVisitRequest
    };
}
