import SearchIcon from '@mui/icons-material/Search';
import { InputBase } from '@mui/material';
import React, { ChangeEvent } from 'react';
import styled, { css } from 'styled-components';
import { I18n } from '../../core/i18n';
import { injectTSDI } from '../../core/tsdi';

const Container = styled.div`
    ${({ theme }) => css`
        position: absolute;
        top: 5px;
        left: 0;
        display: flex;
        align-items: center;
        z-index: 1;
        &:hover: {
            background-color: ${theme.palette.common.white};
        }
        ${[theme.breakpoints.up('sm')]} {
            margin-left: ${theme.spacing(2)};
            width: auto;
        }
    `}
`;

const StyledInput = styled(InputBase)`
    ${({ theme }) => css`
        color: 'inherit';
        input {
            padding: ${theme.spacing(1, 1, 1, 4)};
            transition: ${theme.transitions.create('width')};
            width: 100%;
            ${[theme.breakpoints.up('md')]}: {
                width: 200;
            }
            border-bottom: 1px solid ${theme.palette.grey['900']};
            &[value]:not([value='']) {
                border-bottom-color: ${theme.palette.primary.main};
            }
        }
    `}
`;

const IconContainer = styled(SearchIcon)`
    ${({ theme }) => css`
        width: ${theme.spacing(3)};
        height: 100%;
        position: absolute;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
    `}
`;

interface SearchFilterProps {
    initialValue?: string;
    placeholder?: string;
    onChange(value?: string): void;
}

export const SearchFilter = ({
    initialValue,
    placeholder,
    onChange
}: SearchFilterProps) => {
    const { __ } = injectTSDI(I18n);
    const [value, setValue] = React.useState<string>(initialValue || '');

    React.useEffect(() => {
        setValue(initialValue || '');
    }, [initialValue, setValue]);

    React.useEffect(() => {
        onChange(value || undefined);
    }, [value]);
    return (
        <Container>
            <IconContainer>
                <SearchIcon />
            </IconContainer>
            <StyledInput
                value={value}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setValue(e.target.value);
                }}
                placeholder={placeholder ?? `${__('commons.search')}...`}
                inputProps={{ 'aria-label': 'search' }}
                sx={{ minWidth: 450 }}
            />
        </Container>
    );
};
