/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface FacilityAreaDto {
    center: GeoPointDto;
    visitArea?: FacilityAreaGeometryDto;
    checkInArea?: FacilityAreaGeometryDto;
}

export interface FacilityAreaGeometryDto {
    polygons: GeoPolygonDto[];
}

export interface GeoPointDto {
    /** @format double */
    latitude: number;
    /** @format double */
    longitude: number;
}

export interface GeoPolygonDto {
    points: GeoPointDto[];
}

export interface AddressDto {
    /** @format int64 */
    id?: number;
    countryWithTranslation?: CountryDto;
    city?: string;
    street?: string;
    houseNumber?: string;
    zip?: string;
    /** @format double */
    latitude?: number;
    /** @format double */
    longitude?: number;
}

export interface ContactDto {
    /** @format int64 */
    id?: number;
    value: string;
    type: 'PHONE' | 'EMAIL' | 'WEBSITE' | 'FACEBOOK' | 'INSTAGRAM' | 'YOUTUBE';
}

export interface CountryDto {
    code?:
        | 'UNDEFINED'
        | 'AC'
        | 'AD'
        | 'AE'
        | 'AF'
        | 'AG'
        | 'AI'
        | 'AL'
        | 'AM'
        | 'AN'
        | 'AO'
        | 'AQ'
        | 'AR'
        | 'AS'
        | 'AT'
        | 'AU'
        | 'AW'
        | 'AX'
        | 'AZ'
        | 'BA'
        | 'BB'
        | 'BD'
        | 'BE'
        | 'BF'
        | 'BG'
        | 'BH'
        | 'BI'
        | 'BJ'
        | 'BL'
        | 'BM'
        | 'BN'
        | 'BO'
        | 'BQ'
        | 'BR'
        | 'BS'
        | 'BT'
        | 'BU'
        | 'BV'
        | 'BW'
        | 'BY'
        | 'BZ'
        | 'CA'
        | 'CC'
        | 'CD'
        | 'CF'
        | 'CG'
        | 'CH'
        | 'CI'
        | 'CK'
        | 'CL'
        | 'CM'
        | 'CN'
        | 'CO'
        | 'CP'
        | 'CR'
        | 'CS'
        | 'CU'
        | 'CV'
        | 'CW'
        | 'CX'
        | 'CY'
        | 'CZ'
        | 'DE'
        | 'DG'
        | 'DJ'
        | 'DK'
        | 'DM'
        | 'DO'
        | 'DZ'
        | 'EA'
        | 'EC'
        | 'EE'
        | 'EG'
        | 'EH'
        | 'ER'
        | 'ES'
        | 'ET'
        | 'EU'
        | 'EZ'
        | 'FI'
        | 'FJ'
        | 'FK'
        | 'FM'
        | 'FO'
        | 'FR'
        | 'FX'
        | 'GA'
        | 'GB'
        | 'GD'
        | 'GE'
        | 'GF'
        | 'GG'
        | 'GH'
        | 'GI'
        | 'GL'
        | 'GM'
        | 'GN'
        | 'GP'
        | 'GQ'
        | 'GR'
        | 'GS'
        | 'GT'
        | 'GU'
        | 'GW'
        | 'GY'
        | 'HK'
        | 'HM'
        | 'HN'
        | 'HR'
        | 'HT'
        | 'HU'
        | 'IC'
        | 'ID'
        | 'IE'
        | 'IL'
        | 'IM'
        | 'IN'
        | 'IO'
        | 'IQ'
        | 'IR'
        | 'IS'
        | 'IT'
        | 'JE'
        | 'JM'
        | 'JO'
        | 'JP'
        | 'KE'
        | 'KG'
        | 'KH'
        | 'KI'
        | 'KM'
        | 'KN'
        | 'KP'
        | 'KR'
        | 'KW'
        | 'KY'
        | 'KZ'
        | 'LA'
        | 'LB'
        | 'LC'
        | 'LI'
        | 'LK'
        | 'LR'
        | 'LS'
        | 'LT'
        | 'LU'
        | 'LV'
        | 'LY'
        | 'MA'
        | 'MC'
        | 'MD'
        | 'ME'
        | 'MF'
        | 'MG'
        | 'MH'
        | 'MK'
        | 'ML'
        | 'MM'
        | 'MN'
        | 'MO'
        | 'MP'
        | 'MQ'
        | 'MR'
        | 'MS'
        | 'MT'
        | 'MU'
        | 'MV'
        | 'MW'
        | 'MX'
        | 'MY'
        | 'MZ'
        | 'NA'
        | 'NC'
        | 'NE'
        | 'NF'
        | 'NG'
        | 'NI'
        | 'NL'
        | 'NO'
        | 'NP'
        | 'NR'
        | 'NT'
        | 'NU'
        | 'NZ'
        | 'OM'
        | 'PA'
        | 'PE'
        | 'PF'
        | 'PG'
        | 'PH'
        | 'PK'
        | 'PL'
        | 'PM'
        | 'PN'
        | 'PR'
        | 'PS'
        | 'PT'
        | 'PW'
        | 'PY'
        | 'QA'
        | 'RE'
        | 'RO'
        | 'RS'
        | 'RU'
        | 'RW'
        | 'SA'
        | 'SB'
        | 'SC'
        | 'SD'
        | 'SE'
        | 'SF'
        | 'SG'
        | 'SH'
        | 'SI'
        | 'SJ'
        | 'SK'
        | 'SL'
        | 'SM'
        | 'SN'
        | 'SO'
        | 'SR'
        | 'SS'
        | 'ST'
        | 'SU'
        | 'SV'
        | 'SX'
        | 'SY'
        | 'SZ'
        | 'TA'
        | 'TC'
        | 'TD'
        | 'TF'
        | 'TG'
        | 'TH'
        | 'TJ'
        | 'TK'
        | 'TL'
        | 'TM'
        | 'TN'
        | 'TO'
        | 'TP'
        | 'TR'
        | 'TT'
        | 'TV'
        | 'TW'
        | 'TZ'
        | 'UA'
        | 'UG'
        | 'UK'
        | 'UM'
        | 'US'
        | 'UY'
        | 'UZ'
        | 'VA'
        | 'VC'
        | 'VE'
        | 'VG'
        | 'VI'
        | 'VN'
        | 'VU'
        | 'WF'
        | 'WS'
        | 'XI'
        | 'XU'
        | 'XK'
        | 'YE'
        | 'YT'
        | 'YU'
        | 'ZA'
        | 'ZM'
        | 'ZR'
        | 'ZW';
    translation?: string;
}

export interface CustomFacilityUpdateDto {
    name: string;
    category:
        | 'FITNESS_GYM'
        | 'SKI_SLOPE'
        | 'ICE_RINK'
        | 'SPORTS_CENTER'
        | 'YOGA_AND_PILATES'
        | 'MARTIAL_ARTS_SCHOOL'
        | 'DANCE_SCHOOL'
        | 'EMS_STUDIO'
        | 'CROSSFIT_STUDIO'
        | 'CLIMBING_HALL'
        | 'SOCCER_CLUB'
        | 'HANDBALL_CLUB'
        | 'TENNIS_FACILITY'
        | 'SQUASH_FACILITY'
        | 'BADMINTON_FACILITY'
        | 'SWIMMING_POOL'
        | 'BASKETBALL_CLUB'
        | 'VOLLEYBALL_CLUB'
        | 'TABLE_TENNIS_FACILITY'
        | 'OTHER';
    address: AddressDto;
    contacts: ContactDto[];
}

export interface CustomFacilityOriginDto {
    mySportsId?: string;
    email?: string;
    tenant?: string;
    source:
        | 'OSM'
        | 'SALESFORCE'
        | 'MYSPORTS'
        | 'MOVE_REPUBLIC'
        | 'MAGICLINE'
        | 'GOOGLE_PLACES'
        | 'ADMIN_PORTAL'
        | 'MANUAL';
}

export interface CustomFacilitySearchDto {
    /** @format int64 */
    id: number;
    name: string;
    status: 'NEW' | 'VERIFIED' | 'DELETED';
    facilityType: 'MAGICLINE_STUDIO' | 'CUSTOM';
    category: SelectEntryDto;
    address: AddressDto;
    contacts: ContactDto[];
    origin: CustomFacilityOriginDto;
    /**
     * @deprecated
     * @format date-time
     */
    creationDate?: string;
    /** @format date-time */
    createdDate: string;
}

export interface SelectEntryDto {
    value?: string;
    label?: string;
}

export interface FacilityAliasDto {
    value?: string;
}

export interface CustomFacilityManualCreateDto {
    name: string;
    category:
        | 'FITNESS_GYM'
        | 'SKI_SLOPE'
        | 'ICE_RINK'
        | 'SPORTS_CENTER'
        | 'YOGA_AND_PILATES'
        | 'MARTIAL_ARTS_SCHOOL'
        | 'DANCE_SCHOOL'
        | 'EMS_STUDIO'
        | 'CROSSFIT_STUDIO'
        | 'CLIMBING_HALL'
        | 'SOCCER_CLUB'
        | 'HANDBALL_CLUB'
        | 'TENNIS_FACILITY'
        | 'SQUASH_FACILITY'
        | 'BADMINTON_FACILITY'
        | 'SWIMMING_POOL'
        | 'BASKETBALL_CLUB'
        | 'VOLLEYBALL_CLUB'
        | 'TABLE_TENNIS_FACILITY'
        | 'OTHER';
    address: AddressDto;
}

export interface CreatedObjectDto {
    /** @format int64 */
    id: number;
}

export interface CdnImageKey {
    bucket: string;
    key: string;
}

export interface FacilityDto {
    /** @format int64 */
    id: number;
    name: string;
    status: 'NEW' | 'VERIFIED' | 'DELETED';
    facilityType: 'MAGICLINE_STUDIO' | 'CUSTOM';
    /** @deprecated */
    category?:
        | 'FITNESS_GYM'
        | 'SKI_SLOPE'
        | 'ICE_RINK'
        | 'SPORTS_CENTER'
        | 'YOGA_AND_PILATES'
        | 'MARTIAL_ARTS_SCHOOL'
        | 'DANCE_SCHOOL'
        | 'EMS_STUDIO'
        | 'CROSSFIT_STUDIO'
        | 'CLIMBING_HALL'
        | 'SOCCER_CLUB'
        | 'HANDBALL_CLUB'
        | 'TENNIS_FACILITY'
        | 'SQUASH_FACILITY'
        | 'BADMINTON_FACILITY'
        | 'SWIMMING_POOL'
        | 'BASKETBALL_CLUB'
        | 'VOLLEYBALL_CLUB'
        | 'TABLE_TENNIS_FACILITY'
        | 'OTHER';
    categoryWithTranslation: SelectEntryDto;
    logo: CdnImageKey;
    address: AddressDto;
    contacts?: ContactDto[];
    zoneId?: string;
}

export interface MsPageFacilityDto {
    content?: FacilityDto[];
    /** @format int32 */
    number?: number;
    /** @format int32 */
    size?: number;
    /** @format int64 */
    totalElements?: number;
    /** @format int32 */
    totalPages?: number;
}

export interface GoogleFacilityDetailsDto {
    name: string;
    city: string;
    street: string;
    houseNumber: string;
    zip: string;
    countryCode:
        | 'UNDEFINED'
        | 'AC'
        | 'AD'
        | 'AE'
        | 'AF'
        | 'AG'
        | 'AI'
        | 'AL'
        | 'AM'
        | 'AN'
        | 'AO'
        | 'AQ'
        | 'AR'
        | 'AS'
        | 'AT'
        | 'AU'
        | 'AW'
        | 'AX'
        | 'AZ'
        | 'BA'
        | 'BB'
        | 'BD'
        | 'BE'
        | 'BF'
        | 'BG'
        | 'BH'
        | 'BI'
        | 'BJ'
        | 'BL'
        | 'BM'
        | 'BN'
        | 'BO'
        | 'BQ'
        | 'BR'
        | 'BS'
        | 'BT'
        | 'BU'
        | 'BV'
        | 'BW'
        | 'BY'
        | 'BZ'
        | 'CA'
        | 'CC'
        | 'CD'
        | 'CF'
        | 'CG'
        | 'CH'
        | 'CI'
        | 'CK'
        | 'CL'
        | 'CM'
        | 'CN'
        | 'CO'
        | 'CP'
        | 'CR'
        | 'CS'
        | 'CU'
        | 'CV'
        | 'CW'
        | 'CX'
        | 'CY'
        | 'CZ'
        | 'DE'
        | 'DG'
        | 'DJ'
        | 'DK'
        | 'DM'
        | 'DO'
        | 'DZ'
        | 'EA'
        | 'EC'
        | 'EE'
        | 'EG'
        | 'EH'
        | 'ER'
        | 'ES'
        | 'ET'
        | 'EU'
        | 'EZ'
        | 'FI'
        | 'FJ'
        | 'FK'
        | 'FM'
        | 'FO'
        | 'FR'
        | 'FX'
        | 'GA'
        | 'GB'
        | 'GD'
        | 'GE'
        | 'GF'
        | 'GG'
        | 'GH'
        | 'GI'
        | 'GL'
        | 'GM'
        | 'GN'
        | 'GP'
        | 'GQ'
        | 'GR'
        | 'GS'
        | 'GT'
        | 'GU'
        | 'GW'
        | 'GY'
        | 'HK'
        | 'HM'
        | 'HN'
        | 'HR'
        | 'HT'
        | 'HU'
        | 'IC'
        | 'ID'
        | 'IE'
        | 'IL'
        | 'IM'
        | 'IN'
        | 'IO'
        | 'IQ'
        | 'IR'
        | 'IS'
        | 'IT'
        | 'JE'
        | 'JM'
        | 'JO'
        | 'JP'
        | 'KE'
        | 'KG'
        | 'KH'
        | 'KI'
        | 'KM'
        | 'KN'
        | 'KP'
        | 'KR'
        | 'KW'
        | 'KY'
        | 'KZ'
        | 'LA'
        | 'LB'
        | 'LC'
        | 'LI'
        | 'LK'
        | 'LR'
        | 'LS'
        | 'LT'
        | 'LU'
        | 'LV'
        | 'LY'
        | 'MA'
        | 'MC'
        | 'MD'
        | 'ME'
        | 'MF'
        | 'MG'
        | 'MH'
        | 'MK'
        | 'ML'
        | 'MM'
        | 'MN'
        | 'MO'
        | 'MP'
        | 'MQ'
        | 'MR'
        | 'MS'
        | 'MT'
        | 'MU'
        | 'MV'
        | 'MW'
        | 'MX'
        | 'MY'
        | 'MZ'
        | 'NA'
        | 'NC'
        | 'NE'
        | 'NF'
        | 'NG'
        | 'NI'
        | 'NL'
        | 'NO'
        | 'NP'
        | 'NR'
        | 'NT'
        | 'NU'
        | 'NZ'
        | 'OM'
        | 'PA'
        | 'PE'
        | 'PF'
        | 'PG'
        | 'PH'
        | 'PK'
        | 'PL'
        | 'PM'
        | 'PN'
        | 'PR'
        | 'PS'
        | 'PT'
        | 'PW'
        | 'PY'
        | 'QA'
        | 'RE'
        | 'RO'
        | 'RS'
        | 'RU'
        | 'RW'
        | 'SA'
        | 'SB'
        | 'SC'
        | 'SD'
        | 'SE'
        | 'SF'
        | 'SG'
        | 'SH'
        | 'SI'
        | 'SJ'
        | 'SK'
        | 'SL'
        | 'SM'
        | 'SN'
        | 'SO'
        | 'SR'
        | 'SS'
        | 'ST'
        | 'SU'
        | 'SV'
        | 'SX'
        | 'SY'
        | 'SZ'
        | 'TA'
        | 'TC'
        | 'TD'
        | 'TF'
        | 'TG'
        | 'TH'
        | 'TJ'
        | 'TK'
        | 'TL'
        | 'TM'
        | 'TN'
        | 'TO'
        | 'TP'
        | 'TR'
        | 'TT'
        | 'TV'
        | 'TW'
        | 'TZ'
        | 'UA'
        | 'UG'
        | 'UK'
        | 'UM'
        | 'US'
        | 'UY'
        | 'UZ'
        | 'VA'
        | 'VC'
        | 'VE'
        | 'VG'
        | 'VI'
        | 'VN'
        | 'VU'
        | 'WF'
        | 'WS'
        | 'XI'
        | 'XU'
        | 'XK'
        | 'YE'
        | 'YT'
        | 'YU'
        | 'ZA'
        | 'ZM'
        | 'ZR'
        | 'ZW';
    location: GeoPointDto;
}

export interface FacilitySearchSimpleDto {
    /** @format int64 */
    id: number;
    name: string;
    status: 'NEW' | 'VERIFIED' | 'DELETED';
    type: 'MAGICLINE_STUDIO' | 'CUSTOM';
    source:
        | 'OSM'
        | 'SALESFORCE'
        | 'MYSPORTS'
        | 'MOVE_REPUBLIC'
        | 'MAGICLINE'
        | 'GOOGLE_PLACES'
        | 'ADMIN_PORTAL'
        | 'MANUAL';
    category: SelectEntryDto;
    address: AddressDto;
}

export interface MsPageFacilitySearchSimpleDto {
    content?: FacilitySearchSimpleDto[];
    /** @format int32 */
    number?: number;
    /** @format int32 */
    size?: number;
    /** @format int64 */
    totalElements?: number;
    /** @format int32 */
    totalPages?: number;
}

export interface MsPageCustomFacilitySearchDto {
    content?: CustomFacilitySearchDto[];
    /** @format int32 */
    number?: number;
    /** @format int32 */
    size?: number;
    /** @format int64 */
    totalElements?: number;
    /** @format int32 */
    totalPages?: number;
}

export interface CustomFacilityDetailsDto {
    internalData: CustomFacilitySearchDto;
    externalData: ExternalFacilityDto[];
}

export interface EntryDto {
    key: string;
    value: object;
}

export interface ExternalFacilityDataDto {
    id: string;
    name: string;
    address: AddressDto;
}

export interface ExternalFacilityDto {
    poiProvider: 'GOOGLE';
    data: ExternalFacilityDataDto;
    additionalProperties: EntryDto[];
    phone?: string;
    website?: string;
}

export interface CountryOptionDto {
    key?:
        | 'UNDEFINED'
        | 'AC'
        | 'AD'
        | 'AE'
        | 'AF'
        | 'AG'
        | 'AI'
        | 'AL'
        | 'AM'
        | 'AN'
        | 'AO'
        | 'AQ'
        | 'AR'
        | 'AS'
        | 'AT'
        | 'AU'
        | 'AW'
        | 'AX'
        | 'AZ'
        | 'BA'
        | 'BB'
        | 'BD'
        | 'BE'
        | 'BF'
        | 'BG'
        | 'BH'
        | 'BI'
        | 'BJ'
        | 'BL'
        | 'BM'
        | 'BN'
        | 'BO'
        | 'BQ'
        | 'BR'
        | 'BS'
        | 'BT'
        | 'BU'
        | 'BV'
        | 'BW'
        | 'BY'
        | 'BZ'
        | 'CA'
        | 'CC'
        | 'CD'
        | 'CF'
        | 'CG'
        | 'CH'
        | 'CI'
        | 'CK'
        | 'CL'
        | 'CM'
        | 'CN'
        | 'CO'
        | 'CP'
        | 'CR'
        | 'CS'
        | 'CU'
        | 'CV'
        | 'CW'
        | 'CX'
        | 'CY'
        | 'CZ'
        | 'DE'
        | 'DG'
        | 'DJ'
        | 'DK'
        | 'DM'
        | 'DO'
        | 'DZ'
        | 'EA'
        | 'EC'
        | 'EE'
        | 'EG'
        | 'EH'
        | 'ER'
        | 'ES'
        | 'ET'
        | 'EU'
        | 'EZ'
        | 'FI'
        | 'FJ'
        | 'FK'
        | 'FM'
        | 'FO'
        | 'FR'
        | 'FX'
        | 'GA'
        | 'GB'
        | 'GD'
        | 'GE'
        | 'GF'
        | 'GG'
        | 'GH'
        | 'GI'
        | 'GL'
        | 'GM'
        | 'GN'
        | 'GP'
        | 'GQ'
        | 'GR'
        | 'GS'
        | 'GT'
        | 'GU'
        | 'GW'
        | 'GY'
        | 'HK'
        | 'HM'
        | 'HN'
        | 'HR'
        | 'HT'
        | 'HU'
        | 'IC'
        | 'ID'
        | 'IE'
        | 'IL'
        | 'IM'
        | 'IN'
        | 'IO'
        | 'IQ'
        | 'IR'
        | 'IS'
        | 'IT'
        | 'JE'
        | 'JM'
        | 'JO'
        | 'JP'
        | 'KE'
        | 'KG'
        | 'KH'
        | 'KI'
        | 'KM'
        | 'KN'
        | 'KP'
        | 'KR'
        | 'KW'
        | 'KY'
        | 'KZ'
        | 'LA'
        | 'LB'
        | 'LC'
        | 'LI'
        | 'LK'
        | 'LR'
        | 'LS'
        | 'LT'
        | 'LU'
        | 'LV'
        | 'LY'
        | 'MA'
        | 'MC'
        | 'MD'
        | 'ME'
        | 'MF'
        | 'MG'
        | 'MH'
        | 'MK'
        | 'ML'
        | 'MM'
        | 'MN'
        | 'MO'
        | 'MP'
        | 'MQ'
        | 'MR'
        | 'MS'
        | 'MT'
        | 'MU'
        | 'MV'
        | 'MW'
        | 'MX'
        | 'MY'
        | 'MZ'
        | 'NA'
        | 'NC'
        | 'NE'
        | 'NF'
        | 'NG'
        | 'NI'
        | 'NL'
        | 'NO'
        | 'NP'
        | 'NR'
        | 'NT'
        | 'NU'
        | 'NZ'
        | 'OM'
        | 'PA'
        | 'PE'
        | 'PF'
        | 'PG'
        | 'PH'
        | 'PK'
        | 'PL'
        | 'PM'
        | 'PN'
        | 'PR'
        | 'PS'
        | 'PT'
        | 'PW'
        | 'PY'
        | 'QA'
        | 'RE'
        | 'RO'
        | 'RS'
        | 'RU'
        | 'RW'
        | 'SA'
        | 'SB'
        | 'SC'
        | 'SD'
        | 'SE'
        | 'SF'
        | 'SG'
        | 'SH'
        | 'SI'
        | 'SJ'
        | 'SK'
        | 'SL'
        | 'SM'
        | 'SN'
        | 'SO'
        | 'SR'
        | 'SS'
        | 'ST'
        | 'SU'
        | 'SV'
        | 'SX'
        | 'SY'
        | 'SZ'
        | 'TA'
        | 'TC'
        | 'TD'
        | 'TF'
        | 'TG'
        | 'TH'
        | 'TJ'
        | 'TK'
        | 'TL'
        | 'TM'
        | 'TN'
        | 'TO'
        | 'TP'
        | 'TR'
        | 'TT'
        | 'TV'
        | 'TW'
        | 'TZ'
        | 'UA'
        | 'UG'
        | 'UK'
        | 'UM'
        | 'US'
        | 'UY'
        | 'UZ'
        | 'VA'
        | 'VC'
        | 'VE'
        | 'VG'
        | 'VI'
        | 'VN'
        | 'VU'
        | 'WF'
        | 'WS'
        | 'XI'
        | 'XU'
        | 'XK'
        | 'YE'
        | 'YT'
        | 'YU'
        | 'ZA'
        | 'ZM'
        | 'ZR'
        | 'ZW';
    value?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** request path */
    path: string;
    /** content type of request body */
    type?: ContentType;
    /** query params */
    query?: QueryParamsType;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseFormat;
    /** request body */
    body?: unknown;
    /** base url */
    baseUrl?: string;
    /** request cancellation token */
    cancelToken?: CancelToken;
}

export type RequestParams = Omit<
    FullRequestParams,
    'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
    baseUrl?: string;
    baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
    securityWorker?: (
        securityData: SecurityDataType | null
    ) => Promise<RequestParams | void> | RequestParams | void;
    customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
    extends Response {
    data: D;
    error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
    public baseUrl: string = '/studio-api';
    private securityData: SecurityDataType | null = null;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private abortControllers = new Map<CancelToken, AbortController>();
    private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
        fetch(...fetchParams);

    private baseApiParams: RequestParams = {
        credentials: 'same-origin',
        headers: {},
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    };

    constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
        Object.assign(this, apiConfig);
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data;
    };

    protected encodeQueryParam(key: string, value: any) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
    }

    protected addQueryParam(query: QueryParamsType, key: string) {
        return this.encodeQueryParam(key, query[key]);
    }

    protected addArrayQueryParam(query: QueryParamsType, key: string) {
        const value = query[key];
        return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
    }

    protected toQueryString(rawQuery?: QueryParamsType): string {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter(
            (key) => 'undefined' !== typeof query[key]
        );
        return keys
            .map((key) =>
                Array.isArray(query[key])
                    ? this.addArrayQueryParam(query, key)
                    : this.addQueryParam(query, key)
            )
            .join('&');
    }

    protected addQueryParams(rawQuery?: QueryParamsType): string {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : '';
    }

    private contentFormatters: Record<ContentType, (input: any) => any> = {
        [ContentType.Json]: (input: any) =>
            input !== null &&
            (typeof input === 'object' || typeof input === 'string')
                ? JSON.stringify(input)
                : input,
        [ContentType.Text]: (input: any) =>
            input !== null && typeof input !== 'string'
                ? JSON.stringify(input)
                : input,
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(
                    key,
                    property instanceof Blob
                        ? property
                        : typeof property === 'object' && property !== null
                          ? JSON.stringify(property)
                          : `${property}`
                );
                return formData;
            }, new FormData()),
        [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
    };

    protected mergeRequestParams(
        params1: RequestParams,
        params2?: RequestParams
    ): RequestParams {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {})
            }
        };
    }

    protected createAbortSignal = (
        cancelToken: CancelToken
    ): AbortSignal | undefined => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                return abortController.signal;
            }
            return void 0;
        }

        const abortController = new AbortController();
        this.abortControllers.set(cancelToken, abortController);
        return abortController.signal;
    };

    public abortRequest = (cancelToken: CancelToken) => {
        const abortController = this.abortControllers.get(cancelToken);

        if (abortController) {
            abortController.abort();
            this.abortControllers.delete(cancelToken);
        }
    };

    public request = async <T = any, E = any>({
        body,
        secure,
        path,
        type,
        query,
        format,
        baseUrl,
        cancelToken,
        ...params
    }: FullRequestParams): Promise<HttpResponse<T, E>> => {
        const secureParams =
            ((typeof secure === 'boolean'
                ? secure
                : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {};
        const requestParams = this.mergeRequestParams(params, secureParams);
        const queryString = query && this.toQueryString(query);
        const payloadFormatter =
            this.contentFormatters[type || ContentType.Json];
        const responseFormat = format || requestParams.format;

        return this.customFetch(
            `${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`,
            {
                ...requestParams,
                headers: {
                    ...(requestParams.headers || {}),
                    ...(type && type !== ContentType.FormData
                        ? { 'Content-Type': type }
                        : {})
                },
                signal:
                    (cancelToken
                        ? this.createAbortSignal(cancelToken)
                        : requestParams.signal) || null,
                body:
                    typeof body === 'undefined' || body === null
                        ? null
                        : payloadFormatter(body)
            }
        ).then(async (response) => {
            const r = response.clone() as HttpResponse<T, E>;
            r.data = null as unknown as T;
            r.error = null as unknown as E;

            const data = !responseFormat
                ? r
                : await response[responseFormat]()
                      .then((data) => {
                          if (r.ok) {
                              r.data = data;
                          } else {
                              r.error = data;
                          }
                          return r;
                      })
                      .catch((e) => {
                          r.error = e;
                          return r;
                      });

            if (cancelToken) {
                this.abortControllers.delete(cancelToken);
            }

            if (!response.ok) throw data;
            return data;
        });
    };
}

/**
 * @title SportAlliance Studio API
 * @version v1
 * @license MySports License (https://www.mysports-rewards.com)
 * @baseUrl /studio-api
 * @contact MySports Contact <info@mysports.com>
 *
 * Service that provides Studio and Facility APIs
 */
export class Api<
    SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
    v1 = {
        /**
         * No description
         *
         * @tags closed-facility-controller
         * @name ClosedFacilityControllerGetFacilityArea
         * @request GET:/v1/closed/facilities/{facilityId}/area
         * @secure
         */
        closedFacilityControllerGetFacilityArea: (
            facilityId: number,
            params: RequestParams = {}
        ) =>
            this.request<FacilityAreaDto, any>({
                path: `/v1/closed/facilities/${facilityId}/area`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags closed-facility-controller
         * @name ClosedFacilityControllerUpdateFacilityArea
         * @request PUT:/v1/closed/facilities/{facilityId}/area
         * @secure
         */
        closedFacilityControllerUpdateFacilityArea: (
            facilityId: number,
            data: FacilityAreaDto,
            params: RequestParams = {}
        ) =>
            this.request<FacilityAreaDto, any>({
                path: `/v1/closed/facilities/${facilityId}/area`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags custom-facility-controller
         * @name CustomFacilityControllerGetDetails
         * @request GET:/v1/closed/customfacilities/{id}
         * @secure
         */
        customFacilityControllerGetDetails: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<CustomFacilityDetailsDto, any>({
                path: `/v1/closed/customfacilities/${id}`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags custom-facility-controller
         * @name CustomFacilityControllerUpdate
         * @request PUT:/v1/closed/customfacilities/{id}
         * @secure
         */
        customFacilityControllerUpdate: (
            id: number,
            data: CustomFacilityUpdateDto,
            params: RequestParams = {}
        ) =>
            this.request<CustomFacilitySearchDto, any>({
                path: `/v1/closed/customfacilities/${id}`,
                method: 'PUT',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags closed-facility-controller
         * @name ClosedFacilityControllerFindAliasesByFacility
         * @request GET:/v1/closed/facilities/{facilityId}/aliases
         * @secure
         */
        closedFacilityControllerFindAliasesByFacility: (
            facilityId: number,
            params: RequestParams = {}
        ) =>
            this.request<FacilityAliasDto[], any>({
                path: `/v1/closed/facilities/${facilityId}/aliases`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags closed-facility-controller
         * @name ClosedFacilityControllerCreateAlias
         * @request POST:/v1/closed/facilities/{facilityId}/aliases
         * @secure
         */
        closedFacilityControllerCreateAlias: (
            facilityId: number,
            data: FacilityAliasDto,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/v1/closed/facilities/${facilityId}/aliases`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags custom-facility-controller
         * @name CustomFacilityControllerGetPage
         * @request GET:/v1/closed/customfacilities
         * @secure
         */
        customFacilityControllerGetPage: (
            query?: {
                status?: 'NEW' | 'VERIFIED' | 'DELETED';
                search?: string;
                creatorMySportsId?: string;
                /**
                 * @format int32
                 * @min 0
                 * @max 2147483647
                 */
                page?: number;
                /**
                 * @format int32
                 * @max 2147483647
                 */
                size?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<MsPageCustomFacilitySearchDto, any>({
                path: `/v1/closed/customfacilities`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags custom-facility-controller
         * @name CustomFacilityControllerCreateManually
         * @request POST:/v1/closed/customfacilities
         * @secure
         */
        customFacilityControllerCreateManually: (
            data: CustomFacilityManualCreateDto,
            params: RequestParams = {}
        ) =>
            this.request<CreatedObjectDto, any>({
                path: `/v1/closed/customfacilities`,
                method: 'POST',
                body: data,
                secure: true,
                type: ContentType.Json,
                ...params
            }),

        /**
         * No description
         *
         * @tags custom-facility-controller
         * @name CustomFacilityControllerVerify
         * @request POST:/v1/closed/customfacilities/{id}/verify
         * @secure
         */
        customFacilityControllerVerify: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/v1/closed/customfacilities/${id}/verify`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags custom-facility-controller
         * @name CustomFacilityControllerReject
         * @request POST:/v1/closed/customfacilities/{id}/reject
         * @secure
         */
        customFacilityControllerReject: (
            id: number,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/v1/closed/customfacilities/${id}/reject`,
                method: 'POST',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags public-facility-controller
         * @name PublicFacilityControllerGetFacilities
         * @request GET:/v1/public/facilities
         * @secure
         */
        publicFacilityControllerGetFacilities: (
            query?: {
                /** @format int64 */
                excludeId?: number;
                geoRequestType?: 'CIRCLE' | 'RECTANGLE';
                latitude?: number[];
                longitude?: number[];
                /** @format double */
                radius?: number;
                /**
                 * @format int32
                 * @min 0
                 * @max 2147483647
                 */
                page?: number;
                /**
                 * @format int32
                 * @max 2147483647
                 */
                size?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<MsPageFacilityDto, any>({
                path: `/v1/public/facilities`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags public-facility-controller
         * @name PublicFacilityControllerGetAvailableCategories
         * @request GET:/v1/public/facilities/categories
         * @secure
         */
        publicFacilityControllerGetAvailableCategories: (
            params: RequestParams = {}
        ) =>
            this.request<SelectEntryDto[], any>({
                path: `/v1/public/facilities/categories`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags google-nearby-search-controller
         * @name GoogleNearbySearchControllerDecodeExternalFacilityFromGoogleUrl
         * @request GET:/v1/closed/google-facilities/by-location
         * @secure
         */
        googleNearbySearchControllerDecodeExternalFacilityFromGoogleUrl: (
            query: {
                location: string;
            },
            params: RequestParams = {}
        ) =>
            this.request<GoogleFacilityDetailsDto, any>({
                path: `/v1/closed/google-facilities/by-location`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags closed-facility-controller
         * @name ClosedFacilityControllerGetPage
         * @request GET:/v1/closed/facilities
         * @secure
         */
        closedFacilityControllerGetPage: (
            query?: {
                /** @format int64 */
                excludeId?: number;
                status?: ('NEW' | 'VERIFIED' | 'DELETED')[];
                type?: ('MAGICLINE_STUDIO' | 'CUSTOM')[];
                source?: (
                    | 'OSM'
                    | 'SALESFORCE'
                    | 'MYSPORTS'
                    | 'MOVE_REPUBLIC'
                    | 'MAGICLINE'
                    | 'GOOGLE_PLACES'
                    | 'ADMIN_PORTAL'
                    | 'MANUAL'
                )[];
                search?: string;
                geoRequestType?: 'CIRCLE' | 'RECTANGLE';
                latitude?: number[];
                longitude?: number[];
                /** @format double */
                radius?: number;
                /**
                 * @format int32
                 * @min 0
                 * @max 2147483647
                 */
                page?: number;
                /**
                 * @format int32
                 * @max 2147483647
                 */
                size?: number;
            },
            params: RequestParams = {}
        ) =>
            this.request<MsPageFacilitySearchSimpleDto, any>({
                path: `/v1/closed/facilities`,
                method: 'GET',
                query: query,
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags closed-facility-controller
         * @name ClosedFacilityControllerGetFacility
         * @request GET:/v1/closed/facilities/{facilityId}
         * @secure
         */
        closedFacilityControllerGetFacility: (
            facilityId: number,
            params: RequestParams = {}
        ) =>
            this.request<FacilityDto, any>({
                path: `/v1/closed/facilities/${facilityId}`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags country-controller
         * @name CountryControllerGetAvailableCountries
         * @request GET:/v1/closed/countries
         * @secure
         */
        countryControllerGetAvailableCountries: (params: RequestParams = {}) =>
            this.request<CountryOptionDto[], any>({
                path: `/v1/closed/countries`,
                method: 'GET',
                secure: true,
                ...params
            }),

        /**
         * No description
         *
         * @tags closed-facility-controller
         * @name ClosedFacilityControllerRemoveAlias
         * @request DELETE:/v1/closed/facilities/{facilityId}/aliases/{alias}
         * @secure
         */
        closedFacilityControllerRemoveAlias: (
            facilityId: number,
            alias: string,
            params: RequestParams = {}
        ) =>
            this.request<void, any>({
                path: `/v1/closed/facilities/${facilityId}/aliases/${alias}`,
                method: 'DELETE',
                secure: true,
                ...params
            })
    };
}
